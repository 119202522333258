$(function () {
  let media991 = window.matchMedia("(max-width: 991px)");
  let media767 = window.matchMedia("(max-width: 767px)");
  let media575 = window.matchMedia("(max-width: 575px)");

  if (media991.matches) {
    on991();
  } else {
    off991();
  }

  if (media767.matches) {
    on767();
  } else {
    off767();
  }

  if (media575.matches) {
    on575();
  } else {
    off575();
  }

  media991.addEventListener("change", function (media991) {
    if (media991.matches) {
      on991();
    } else {
      off991();
    }
  });

  media767.addEventListener("change", function (media767) {
    if (media767.matches) {
      on767();
    } else {
      off767();
    }
  });

  media575.addEventListener("change", function (media575) {
    if (media575.matches) {
      on575();
    } else {
      off575();
    }
  });

  function on575() {
    $('.valuation__box').insertAfter('.valuation');
  }

  function off575() {
    $('.valuation__box').appendTo('.valuation');
  }

  function on767() {
    $('.hero__box').insertAfter('.hero');
  }

  function off767() {
    $('.hero__box').appendTo('.hero');
  }

  function on991() {
    $(".header__nav").addClass('padding-container');
  }

  function off991() {
    $(".header__nav").removeClass('padding-container');
  }
  
});


